.cursor {
    cursor: pointer;
}
.table-row-color{
    background-color: red;
}

.ant-switch-disabled{
    cursor: not-allowed;
    opacity: 1.0;
}
.disable-btn:hover{
    background-color: #C53630;
    color: white;
    border: 0;
}
.disable-btn{
    background-color: #C53630;
    color: white;
    border: 0;
}
.enable-btn{
    background-color:green;
    color: white;
    border: 0;
}
.enable-btn:hover{
    background-color: green;
    color: white;
    border: 0;
}
@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;