.center {
    display: flex;
    justify-content: center;
}

.red-button {
    padding: 2px 15px;
    background-color: #c53030;
    border-color: #c53030;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
}

.marginBottom {
    margin-bottom: 30px;
}

.invoice-printer .ant-tabs-tab .ant-tabs-tab-active {
    display: none;
}
.invoice-printer{
    max-width: 80%;
    margin: 0 auto;

    .ant-table-thead{
        display: none;
    }

    .ant-table-wrapper{
        
        border: 1px solid rgb(206, 204, 204);
        border-bottom: 0;

        table{
            font-size: 16px;
            color: rgb(38, 38, 38);
        }
    }

    .charge-table{
        border-top: 0;
        table{
            border-top: 2px solid rgb(167, 167, 167);
            border-bottom: 1px solid rgb(206, 204, 204);

            tr{
                &:last-child{
                    background: #ececec;
                    font-weight: 600;
                }
            }
        }
    }
}

.invoice{
    text-align: right;
    margin: 16px 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 40px;
    position: relative;
    letter-spacing: 1px;
}

.custom-spacer-invoice{
    div:first-child{
        flex: 1;
    }
}

.invoice-ribbon{
    height: 25px;
    background-color: #c53030;
    width: 100%;
}

.foodapps-address{
    font-size: 16px;
    max-width: 240px;
    margin-left: 18px;
    margin-top: 20px;
}
@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;