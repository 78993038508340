.ant-card-head-title{
    padding-bottom: 0;
}
.ant-col:nth-child(4n+1) .ant-card{
    background-color:#55D3D3 ;
}
.ant-col:nth-child(2n) .ant-card{
    background-color:#549EF3 ;
}
.ant-col:nth-child(4n+3) .ant-card{

    background-color:#da5c57 ;
}
.ant-col:nth-child(4n+4) .ant-card{
    background-color:#e09f3e ;
}
@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;