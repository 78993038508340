#components-layout-demo-side
  .logo {
  height: 32px;
  margin: 16px;
  background: rgba(
    255,
    255,
    255,
    0.76
  );
}

.site-layout
  .site-layout-background {
  background: #fff;
}

.collapsed-btn {
  position: fixed;
  bottom: 0;
  left: 0;
}

.icon {
  padding: 4px;
  vertical-align: middle;
  font-size: 18px;
}

.user-avatar {
  display: flex;
  margin-left: 14px;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px
    0
    17px
    0;
}

.logo
  > h4 {
  margin-bottom: 0;
  margin-left: 15px;
}

.side-menu-shadow {
  overflow: auto;
  height: 100vh;
  box-shadow: 0px
    2px
    8px
    rgba(
      0,
      0,
      0,
      0.15
    );
}

._side-menu-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 87vh;
}

._bottom-text {
  margin-left: 15px;
}

._bottom-menu {
  padding-bottom: 50px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #c53030;
  color: white;
  text-align: center;
  align-items: center;
  padding: 5px 0;
}

.footer>p {
  margin-bottom: 0em;
}
@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;