._container {
  padding: 10px;
}

.ant-input-search-button {
  border: 1px solid #d9d9d9;
}

.ant-input-search-button:hover {
  border: 1px solid #d9d9d9;
}
.ant-tabs-tab {
  border: 0 !important;
  padding: 10px 30px !important;
  background-color: #ffeded !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}
.ant-tabs-tab-active {
  background-color: #c53630 !important;
}
.ant-tabs-tab-btn {
  color: #c53630 !important;
}
.ant-card-head {
  border-bottom: 0;
}
.ant-card-head-title {
  text-align: center;
  color: white;
}
.ant-card-body {
  text-align: center;
  font-size: 64px;
  font-weight: 600;
  padding: 0px 15px 15px;
  color: white;
}
.ant-card {
  margin-bottom: 15px;
}

@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;