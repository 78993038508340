.justify-content-center {
    width: 100%;
    justify-content: center;
    padding: 5%;
}

.card {
    border-top: 5px solid hsl(180, 62%, 55%);
}

.card-text {
    text-align: center;
    padding: 10% 2%;
    font-size: xx-large;
    color: hsl(234, 12%, 34%);
}
@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;