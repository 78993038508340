.simpleLoader {
    position: fixed;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 50%;
    left: 57%;
    transform: translate(-50%, -50%);
    z-index: 999;
    height: 100%;
    width: 100%;
}

.styledLoader {
    position: fixed;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 71, 79, 0.5);
}
@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;