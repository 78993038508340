.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #d7ece9b7;
}

.left-side {
  height: 100vh;
  position: relative;
  background: #ffffff;
}

@media screen and (max-width: 767px) {
  .right-side {
    display: none;
  }

  .left-side {
    height: 100vh;
    width: 100%;
    position: relative;
    background: #ffffff;
  }

  .ant-col-12 {
    flex: 0
      0
      100%;
    max-width: 90%;
  }
}

.logo {
  flex: none;
  margin: 0 0 0 200px;
  padding: 4px;
}

.light {
  width: 27px;
  height: 36px;
  margin-right: 15px;
}

.container {
  width: 290px;
}

.logo-container {
  height: 48px;
  width: 48px;
  margin-left: 68px;
}

.back-text {
  font-size: 14px;
  margin-left: 10px;
  color: #1890ff;
}

.back-container {
  margin-top: 24px;
  margin-left: 120px;
  margin-bottom: 29px;
  cursor: pointer;
}

.padding-bottom {
  padding-bottom: 24px;
}

.full-width {
  width: 100%;
}

.remember-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.ant-tabs-top
  > .ant-tabs-nav::before,
.ant-tabs-bottom
  > .ant-tabs-nav::before,
.ant-tabs-top
  > div
  > .ant-tabs-nav::before,
.ant-tabs-bottom
  > div
  > .ant-tabs-nav::before {
  position: relative;
}

.verifyCode {
  width: 40px;
}

.OTP {
  box-sizing: border-box;
  width: 40;
  height: 40;
  padding: 15;
  background: #ffffff;
  border: 1px
    solid
    #d9d9d9;
  border-radius: 2px;
  outline: none;
}

.backButton {
  display: flex;
  justify-content: start;
  border: 1px
    dashed
    orange;
}

.ant-input-number-handler {
  display: none;
}

@primary-color: #c53030;@link-color: #c53030;@success-color: #4BB543;@text-color-secondary: #8c8c8c;@btn-link-hover-bg: transparent;@select-border-color: #D9D9D9;@error-color: crimson;@heading-color: #c53030;@text-color: #c53030;@disabled-color: rgba(0, 0, 0, 0.25);@input-border-color: #D9D9D9;@layout-sider-background: #FFFFFF;@item-active-bg: #E6FFFB;@mentions-dropdown-bg: #E6FFFB;@menu-item-active-bg: #E6FFFB;@border-color-base: #c53030;@radio-button-checked-bg: #c53030;@radio-button-hover-color: #c53030;@radio-button-active-color: #FFFFFF;